import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const MatrixRain = () => {
  const [matrixChars, setMatrixChars] = useState([]);

  useEffect(() => {
    const chars = '如梦又似幻如露亦如电';
    const margin = 40;
    const screenWidth = window.innerWidth - (margin * 2);
    const columns = Math.floor(screenWidth / 20);
    const initialChars = Array(columns * 2).fill(0).map(() => ({
      char: chars[Math.floor(Math.random() * chars.length)],
      x: margin + Math.random() * screenWidth,
      y: Math.random() * window.innerHeight,
      speed: 0.5 + Math.random() * 2,
      size: 16 + Math.random() * 8
    }));
    
    setMatrixChars(initialChars);

    const interval = setInterval(() => {
      setMatrixChars(prev => prev.map(char => {
        const newY = char.y + char.speed;
        
        if (newY > window.innerHeight) {
          return {
            ...char,
            y: -20,
            x: margin + Math.random() * screenWidth,
            char: chars[Math.floor(Math.random() * chars.length)],
            speed: 0.5 + Math.random() * 2,
            size: 16 + Math.random() * 8
          };
        }
        
        return {
          ...char,
          y: newY,
          char: Math.random() < 0.1 ? chars[Math.floor(Math.random() * chars.length)] : char.char
        };
      }));
    }, 30);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="absolute inset-0 pointer-events-none overflow-hidden" lang="zh">
      {matrixChars.map((char, i) => (
        <div
          key={i}
          className="absolute matrix-text matrix-breathe"
          style={{
            left: `${char.x}px`,
            top: `${char.y}px`,
            fontSize: `${char.size}px`,
            transform: 'translate(-50%, -50%)',
            textShadow: '0 0 8px rgba(0, 255, 0, 0.8)'
          }}
        >
          {char.char}
        </div>
      ))}
    </div>
  );
};

const Typewriter = ({ text, onComplete, initialDelay = 0 }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startTyping, setStartTyping] = useState(false);

  useEffect(() => {
    // Initial delay before starting to type
    const startDelay = setTimeout(() => {
      setStartTyping(true);
    }, initialDelay);

    return () => clearTimeout(startDelay);
  }, [initialDelay]);

  useEffect(() => {
    if (!startTyping) return;

    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText(text.slice(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);
      }, 100);

      return () => clearTimeout(timeout);
    } else if (onComplete) {
      onComplete();
    }
  }, [currentIndex, text, onComplete, startTyping]);

  return <span>{displayText}</span>;
};

const ContentCard = ({ title, content, image, onClick }) => {
  // Function to process markdown content and add language tags
  const processContent = (content) => {
    // This regex matches Chinese characters
    const chineseRegex = /[\u4e00-\u9fa5]+/g;
    
    return content.replace(chineseRegex, match => `<span lang="zh">${match}</span>`);
  };

  return (
    <div 
      className="border border-green-500 rounded-lg overflow-hidden hover:bg-green-900/20 transition-all cursor-pointer h-full flex flex-col"
      onClick={onClick}
    >
      {image && (
        <div className="w-full">
          <img 
            src={image} 
            alt={title}
            className="w-full h-auto"
          />
        </div>
      )}
      <div className="p-6 flex-grow">
        <h2 className="text-2xl mb-4">{title}</h2>
        <div className="text-green-400 prose prose-invert">
          <ReactMarkdown components={{
            // Custom component to handle HTML in markdown
            p: ({ children }) => <p dangerouslySetInnerHTML={{ 
              __html: processContent(children.toString()) 
            }} />
          }}>
            {content || 'Loading...'}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

const WelcomePage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [perfectGameContent, setPerfectGameContent] = useState('');
  const [runningTrainContent, setRunningTrainContent] = useState('');
  const [showMainContent, setShowMainContent] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  const processContent = (content) => {
    const chineseRegex = /[\u4e00-\u9fa5]+/g;
    return content.replace(chineseRegex, match => `<span lang="zh">${match}</span>`);
  };

  useEffect(() => {
    const loadContent = async () => {
      try {
        const perfectGameResponse = await fetch(process.env.PUBLIC_URL + '/content/perfect-game.md');
        if (!perfectGameResponse.ok) throw new Error('Failed to load Perfect Game content');
        const perfectGameText = await perfectGameResponse.text();
        setPerfectGameContent(perfectGameText);

        const runningTrainResponse = await fetch(process.env.PUBLIC_URL + '/content/running-train.md');
        if (!runningTrainResponse.ok) throw new Error('Failed to load Running Train content');
        const runningTrainText = await runningTrainResponse.text();
        setRunningTrainContent(runningTrainText);
      } catch (error) {
        console.error('Error loading content:', error);
      }
    };

    if (isAuthenticated) {
      loadContent();
    }
  }, [isAuthenticated]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.toLowerCase() === 'cyka') {
      setIsAuthenticated(true);
    }
  };

  if (isAuthenticated) {
    return (
      <div className="min-h-screen bg-black text-green-500 p-4">
        <MatrixRain />
        <div className="relative z-10 max-w-6xl mx-auto">
          <h1 className="text-4xl mb-8 opacity-100"> {/* Added opacity-100 */}
            <Typewriter 
              text="Welcome to the Construct, Irene..." 
              onComplete={() => setShowMainContent(true)}
              initialDelay={500}
            />
    
</h1>
          {showMainContent && (
            <div className="grid gap-8 md:grid-cols-2">
              <ContentCard
                title="The Perfect Game"
                content={perfectGameContent}
                image={process.env.PUBLIC_URL + '/images/matrix-code.png'}
                onClick={() => setSelectedContent('perfect-game')}
              />
              <ContentCard
                title="The Running Train"
                content={runningTrainContent}
                onClick={() => setSelectedContent('running-train')}
              />
            </div>
          )}
        </div>

        {selectedContent && (
          <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50 p-4">
            <div className="bg-black border border-green-500 p-6 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-auto">
              <h2 className="text-2xl mb-4">
                {selectedContent === 'perfect-game' ? 'The Perfect Game' : 'The Running Train'}
              </h2>
              <div className="text-green-400">
                <ReactMarkdown components={{
                  p: ({ children }) => <p dangerouslySetInnerHTML={{ 
                    __html: processContent(children.toString()) 
                  }} />
                }}>
                  {selectedContent === 'perfect-game' ? perfectGameContent : runningTrainContent}
                </ReactMarkdown>
              </div>
              <button
                onClick={() => setSelectedContent(null)}
                className="mt-4 px-4 py-2 border border-green-500 rounded hover:bg-green-900/20"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-green-500 flex items-center justify-center p-4">
      <MatrixRain />
      <div className="bg-black border border-green-500 p-8 rounded-lg max-w-md w-full relative z-10">
        <h1 className="text-4xl mb-8">
          <Typewriter 
            text="Wake up, Irene..." 
            onComplete={() => setShowInput(true)}
          />
        </h1>
        {showInput && (
          <div className="fade-breathe">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 bg-black border border-green-500 text-green-500 mb-4"
              placeholder="What's on your mind?"
              autoFocus
            />
            <button
              onClick={handleSubmit}
              className="w-full p-2 bg-green-500 text-black hover:bg-green-400"
            >
              LFG!
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomePage;